import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";
import { useAsync } from "react-async";
import Section from "../components/Shared/Section";
import useInterval from "../hooks/useInterval";
import AdsService from "../services/AdsService";
import GameService from "../services/GameService";
import { usePlace } from "./PlaceContext";

export const ScreensaverContext = createContext();

export const useScreensaver = () => useContext(ScreensaverContext);

const fetch = (placeId, isDemo) => {
  return Promise.all([AdsService.getAds(placeId), GameService.getUpcomingGame(isDemo)]).then((res) => ({
    ads: res[0],
    gameStartsAt: res[1]?.startDate,
  }));
};

const promiseFn = ({ placeId, isDemo }) => fetch(placeId, isDemo);
const deferFn = ([], { placeId, isDemo }) => fetch(placeId, isDemo);

export const ScreensaverProvider = ({ children }) => {
  const place = usePlace();
  const { data, run: sync } = useAsync({
    promiseFn,
    deferFn,
    placeId: place.id,
    isDemo: place.isDemo,
  });

  useInterval(() => sync(), 5 * 60 * 1000);

  return data ? (
    <ScreensaverContext.Provider value={{ ads: data.ads, gameStartsAt: data.gameStartsAt }}>
      {children}
    </ScreensaverContext.Provider>
  ) : (
    <Section />
  );
};

ScreensaverProvider.propTypes = {
  children: PropTypes.node,
};

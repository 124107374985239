import PropTypes from "prop-types";
import React, { useCallback, useContext } from "react";
import { useAsync } from "react-async";
import useInterval from "../hooks/useInterval";
import TimeService from "../services/TimeService";
import Durations from "../utils/Durations";

const syncOffsetTime = () => TimeService.getTimeOffset();
const CurrentTimeContext = React.createContext();

export const useCurrentTime = () => {
  const { getCurrentTime } = useContext(CurrentTimeContext);
  return getCurrentTime;
};

export const useCompareTime = () => {
  const { compare } = useContext(CurrentTimeContext);
  return compare;
};

export const CurrentTimeProvider = ({ children }) => {
  const { data: offset, run: syncOffset } = useAsync({ promiseFn: syncOffsetTime, deferFn: syncOffsetTime });

  useInterval(() => syncOffset(), 3 * 60 * 1000);

  const getCurrentTime = useCallback(() => new Date(Date.now() + offset), [offset]);
  const compare = useCallback((date) => Durations.diff(date, getCurrentTime()), [getCurrentTime]);

  return (
    <CurrentTimeContext.Provider value={{ getCurrentTime, compare, offset }}>{children}</CurrentTimeContext.Provider>
  );
};

CurrentTimeProvider.propTypes = {
  children: PropTypes.node,
};

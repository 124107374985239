import styled from "@emotion/styled";

const LeaderboardTitle = () => <Title>Leaderboard</Title>;

const Title = styled.div`
  background: #28264f;
  border-radius: 100px;
  text-align: center;
  padding: 24px;
  font-size: 3vw;
  font-weight: 700;
  color: #ffffff;
`;

export default LeaderboardTitle;

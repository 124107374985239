import { useRef } from "react";
import { useEffect } from "react";

const useInterval = (callback, interval, ...deps) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof interval === "number") {
      intervalRef.current = setInterval(tick, interval);
      return () => clearInterval(intervalRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, ...deps]);

  return intervalRef;
};

export default useInterval;

import api from "../api/api";

const GameService = {
  getActiveGame: async (pub) => {
    const activeGame = await api.get(`/api/v1/games/active${pub.isDemo ? "-demo" : ""}`, {
      headers: { shuffle: true },
    });
    const qrcode = await api.get(`/api/v1/games/${activeGame.data.id}/generate/qr-code/pub/${pub.id}`);

    const game = activeGame.data;
    const { status } = game;

    let leaderboard;
    if (status === "IN_PROGRESS" || status === "LEADERBOARD") {
      leaderboard = await GameService.getLeaderboard(game.id, pub.id);
    }

    return { ...game, qrcode: qrcode.data, leaderboard };
  },
  getLeaderboard: async (gameId, pubId) => {
    const res = await api.get(`/api/v1/games/${gameId}/leaderboard/pub/${pubId}`, {
      params: {
        limit: 10,
      },
    });
    return res.data;
  },
  getUpcomingGame: (isDemo) =>
    api
      .get(`/api/v1/games/upcoming${isDemo ? "-demo" : ""}`)
      .then((res) => res.data)
      .catch((_) => Promise.resolve()),
};

export default GameService;

import { useContext } from "react";
import PlaceContext from "./contexts/PlaceContext";
import Section from "./components/Shared/Section";
import Contact from "screen/Register/Contact";
import PrivateApp from "PrivateApp";

export default function App() {
  const { status, accessToken } = useContext(PlaceContext);

  if (status === "pending") {
    return <Section />;
  }

  if (status === "rejected") {
    return <Contact macAddress={accessToken} />;
  }

  return <PrivateApp />;
}

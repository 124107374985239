import * as React from "react";

function Crown(props) {
  return (
    <svg width={180} height={180} viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#prefix__filter0_d)">
        <g filter="url(#prefix__filter1_di)">
          <path
            d="M87.027 129.479l-11.79-25.744c-.722-1.574-.513-3.534.514-4.836l11.79-14.944c1.34-1.698 3.582-1.698 4.922 0l11.79 14.944c1.028 1.302 1.236 3.262.515 4.836l-11.79 25.744c-1.295 2.828-4.656 2.828-5.951 0z"
            fill="#FFF8DE"
          />
        </g>
        <path
          d="M44.384 127.402c0-2.166 1.81-3.922 4.04-3.922h83.163c2.231 0 4.04 1.756 4.04 3.922v15.664c0 2.165-1.809 3.921-4.04 3.921H48.425c-2.232 0-4.04-1.756-4.04-3.921v-15.664z"
          fill="#FF7A00"
        />
        <path
          d="M64.921 64.147l18.14-36.504c2.866-5.769 11.011-5.769 13.877 0l18.14 36.504c2.165 4.36 7.698 5.687 11.57 2.777l14.666-11.018c5.466-4.107 13.14.381 12.344 7.219l-8.048 69.106c-.461 3.954-3.775 6.933-7.712 6.933H42.101c-3.937 0-7.251-2.979-7.712-6.933l-8.048-69.106c-.796-6.838 6.878-11.326 12.344-7.22l14.666 11.02c3.872 2.909 9.405 1.581 11.57-2.778z"
          fill="url(#prefix__paint0_radial)"
        />
        <path
          d="M83.903 79.651a7.714 7.714 0 0112.197 0l12.034 15.435a7.918 7.918 0 010 9.719L96.1 120.241a7.713 7.713 0 01-12.197 0l-12.034-15.436a7.917 7.917 0 010-9.719l12.034-15.435z"
          fill="url(#prefix__paint1_radial)"
        />
      </g>
      <defs>
        <radialGradient
          id="prefix__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(56.903 -31.896 93.718) scale(83.4392 74.2572)"
        >
          <stop stopColor="#FFEB81" />
          <stop offset={0.682} stopColor="#FFC839" />
        </radialGradient>
        <radialGradient
          id="prefix__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(13.01794 28.5394 -30.39515 13.86443 82.06 90.789)"
        >
          <stop stopColor="#FF7D16" />
          <stop offset={1} stopColor="#FF3E12" />
        </radialGradient>
        <filter
          id="prefix__filter0_d"
          x={16.922}
          y={13.954}
          width={146.156}
          height={142.395}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation={4.681} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="prefix__filter1_di"
          x={65.448}
          y={75.66}
          width={49.108}
          height={67.642}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2.34} />
          <feGaussianBlur stdDeviation={4.681} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.68 0" />
          <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={-2.34} />
          <feGaussianBlur stdDeviation={2.34} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.914286 0 0 0 0 0.55 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
}

export default Crown;

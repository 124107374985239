import { ScreensaverProvider } from "contexts/ScreensaverContext";
import { ProgrammeProvider } from "contexts/ProgrammeContext";

import ProgrammeController from "controllers/ProgrammeController";
import { initFirebase } from "firebase";
import React, { useEffect } from "react";
import Programme from "screen/Programme";

const PrivateApp = () => {
  useEffect(() => {
    initFirebase();
  }, []);

  return (
    <ScreensaverProvider>
      <ProgrammeProvider>
        <ProgrammeController />
        <Programme />
      </ProgrammeProvider>
    </ScreensaverProvider>
  );
};

export default PrivateApp;

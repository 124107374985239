import { css } from "@emotion/css";
import styled from "@emotion/styled";
import CrownIcon from "assets/Icons/CrownIcon";
import { GridColumn, GridRow } from "emotion-flex-grid";
import PropTypes from "prop-types";
import React from "react";

const Leaderboard = ({ data }) => (
  <>
    <GridRow wrap="wrap">
      <GridColumn width={2}>
        <TableHead textAlign="center">Rank</TableHead>
      </GridColumn>
      <GridColumn width={2} />
      <GridColumn width={5}>
        <TableHead>Username</TableHead>
      </GridColumn>
      <GridColumn width={3}>
        <TableHead textAlign="center">Points</TableHead>
      </GridColumn>
    </GridRow>
    <GridRow wrap="wrap">
      {data?.map(({ rank, username, totalPoints }, index) => (
        <React.Fragment key={index}>
          <GridColumn width={2}>
            <Text textAlign="center">{rank}</Text>
          </GridColumn>
          <GridColumn width={2} textAlign="right">
            <div
              className={css`
                margin-right: 8px;
                margin-top: 6px;
              `}
            >
              {rank === 1 ? <CrownIcon /> : <div></div>}
            </div>
          </GridColumn>
          <GridColumn width={5}>
            <Text>{username}</Text>
          </GridColumn>
          <GridColumn width={3}>
            <Text textAlign="center">{totalPoints}</Text>
          </GridColumn>
        </React.Fragment>
      ))}
    </GridRow>
  </>
);

const TableHead = styled.div`
  padding-top: 8px;
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 2vw;
  color: #8175c1;
  opacity: 0.64;
  text-align: ${({ textAlign }) => textAlign || "start"};
`;

const Text = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  color: #ffffff;
  font-weight: bold;
  font-size: 2vw;
  text-align: ${({ textAlign }) => textAlign || "start"};
`;

Leaderboard.propTypes = {
  data: PropTypes.array,
};

export default Leaderboard;

import { css } from "@emotion/css";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FullScreen } from "../../components/Shared/Section";
import { useCompareTime } from "../../contexts/CurrentTimeContext";
import { useScreensaver } from "../../contexts/ScreensaverContext";
import Durations from "../../utils/Durations";

export default function UpcomingGameCountdown({ isSelected }) {
  const [[days, hours, minutes], setTime] = useState([0, 0, 0]);
  const { gameStartsAt } = useScreensaver();
  const compare = useCompareTime();

  const update = useCallback(() => {
    const delta = compare(gameStartsAt);
    setTime(Durations.formatToUpcomingGame(delta).split(":"));
  }, [compare, gameStartsAt]);

  useEffect(() => {
    if (isSelected) {
      update();
    }
  }, [update, isSelected]);

  return (
    <FullScreen
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <Label>Game starts in</Label>
      <div
        className={css`
          display: flex;
          margin-top: 24px;
        `}
      >
        <div>
          <TimeCountDown>{days}</TimeCountDown>
          <Date>Days</Date>
        </div>
        <div>
          <TimeCountDown>{hours}</TimeCountDown>
          <Date>Hours</Date>
        </div>
        <div>
          <TimeCountDown>{minutes}</TimeCountDown>
          <Date>Minutes</Date>
        </div>
      </div>
    </FullScreen>
  );
}

const Label = styled.div`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  width: 100%;
  font-size: 5vw;
`;

const TimeCountDown = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 8vw;
  background-color: #373469;
  border-radius: 16px;
  padding: 32px;
  margin: 16px;
`;

const Date = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 3.5vw;
`;

UpcomingGameCountdown.defaultProps = {
  isSelected: false,
};

UpcomingGameCountdown.propTypes = {
  isSelected: PropTypes.bool,
};

import styled from "@emotion/styled";

const GameQuestionHeader = styled.div`
  padding-bottom: 5px;
  margin-bottom: 5px;
  color: #8175c1;
  letter-spacing: 0.15px;
`;

export default GameQuestionHeader;

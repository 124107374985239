import { css } from "@emotion/css";
import styled from "@emotion/styled";
import Crown from "components/Crown";
import Logo from "components/Logo";
import Points from "components/Points";
import Leaderboard from "components/Shared/Leaderboard";
import { GridColumn, GridRow } from "emotion-flex-grid";
import PropTypes from "prop-types";
import React from "react";
import { AspectRatio } from "react-aspect-ratio";
import LeaderboardTitle from "./LeaderboardTitle";

const FinalLeaderboard = ({ data }) => (
  <Container>
    <GridColumn width={6}>
      <WinnerPlayer>
        <LargeLabel
          className={css`
            font-size: 4vw;
            margin: 4vh 0;
          `}
        >
          The Winner is
        </LargeLabel>
        <Crown />
        <LargeLabel
          className={css`
            font-size: 5vw;
            margin: 4.5vh 0;
          `}
        >
          {data && data[0].username}
        </LargeLabel>
        <Score>
          <span
            className={css`
              margin-right: 16px;
            `}
          >
            <Points />
          </span>
          <LargeLabel
            className={css`
              font-size: 4vw;
              margin: 30px 0;
            `}
          >
            {data && data[0].totalPoints}
          </LargeLabel>
        </Score>
      </WinnerPlayer>
    </GridColumn>
    <GridColumn width={6}>
      <div
        className={css`
          position: relative;
          margin-bottom: 32px;
        `}
      >
        <LeaderboardTitle />
        <div
          className={css`
            position: absolute;
            right: 7%;
            top: -20%;
          `}
        >
          <AspectRatio ratio={0.83} style={{ width: "8vw" }}>
            <Logo />
          </AspectRatio>
        </div>
      </div>
      <Leaderboard data={data} />
    </GridColumn>
  </Container>
);

const Container = styled(GridRow)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const WinnerPlayer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const LargeLabel = styled.div`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
`;

const Score = styled.div`
  width: 35vw;
  height: 18vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28264f;
  border-radius: 5vw;
`;

FinalLeaderboard.propTypes = {
  data: PropTypes.array,
};

export default FinalLeaderboard;

import * as React from "react";

function Points(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M42.79 5.452l28.035 15.292a5.097 5.097 0 012.656 4.476v30.093a5.098 5.098 0 01-2.656 4.475L42.789 75.08a5.098 5.098 0 01-4.88 0L9.872 59.788a5.098 5.098 0 01-2.659-4.475V25.22a5.098 5.098 0 012.661-4.476L37.911 5.452a5.097 5.097 0 014.88 0h-.002z"
        fill="url(#prefix__paint0_radial)"
      />
      <path
        d="M39.163 52.19l-6.844 3.598a2.548 2.548 0 01-3.698-2.686l1.305-7.621a2.55 2.55 0 00-.731-2.258l-5.539-5.396a2.549 2.549 0 011.415-4.348l7.651-1.111a2.549 2.549 0 001.92-1.394l3.422-6.935a2.55 2.55 0 014.57 0l3.423 6.932a2.55 2.55 0 001.92 1.397l7.65 1.111a2.55 2.55 0 011.412 4.348l-5.535 5.396a2.548 2.548 0 00-.734 2.258l1.307 7.62a2.549 2.549 0 01-3.696 2.687l-6.846-3.599a2.548 2.548 0 00-2.372 0z"
        fill="url(#prefix__paint1_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(61.011 -14.531 39.25) scale(48.8885 40.3188)"
        >
          <stop stopColor="#FFEB81" />
          <stop offset={0.682} stopColor="#FFC839" />
        </radialGradient>
        <radialGradient
          id="prefix__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(60.755 -11.281 44.827) scale(23.501 28.2568)"
        >
          <stop stopColor="#FF7D16" />
          <stop offset={1} stopColor="#FF3E12" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Points;

import { getAnalytics, logEvent } from "firebase/analytics";
const FirebaseAnalyticsService = {
  logQuestionLatency: async (clientTime, serverTime, place) => {
    FirebaseAnalyticsService.logEvent("question", clientTime, serverTime, place);
  },

  logGameLatency: async (clientTime, serverTime, place) => {
    FirebaseAnalyticsService.logEvent("game", clientTime, serverTime, place);
  },

  logGameCountDownLatency: async (clientTime, serverTime, place) => {
    FirebaseAnalyticsService.logEvent("game-countdown", clientTime, serverTime, place);
  },
  logEvent: async (name, clientTime, serverTime, place) => {
    const analytics = getAnalytics();
    logEvent(analytics, "latency", {
      name,
      client: "TV",
      latency: Number(clientTime) - Number(serverTime),
      placeId: place?.id,
      palceName: place?.name,
    });
  },
};

export default FirebaseAnalyticsService;

import useTimeout from "hooks/useTimeout";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const CircleTimer = ({ duration, time, clockSize, strokeWidth, onComplete, renderInfo }) => {
  const [play, setPlay] = useState(false);

  useTimeout(() => setPlay(true), time <= duration ? 0 : (time - duration) * 1000);

  return (
    <CountdownCircleTimer
      isPlaying={play}
      duration={duration}
      initialRemainingTime={time < duration ? time : duration}
      colors={[
        ["#BA65F8", 0.5],
        ["#FA4B6A", 0.5],
      ]}
      trailColor="transparent"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      size={clockSize}
      onComplete={onComplete}
    >
      {renderInfo}
    </CountdownCircleTimer>
  );
};

CircleTimer.defaultProps = {
  clockSize: 650,
  strokeWidth: 20,
};

CircleTimer.propTypes = {
  duration: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  clockSize: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  renderInfo: PropTypes.func,
  onComplete: PropTypes.func,
};

export default CircleTimer;

import StompClient from "components/StompClient";
import { useAds, useGame, useLeaderboard, useQuestion } from "contexts/ProgrammeContext";
import { useCallback, useEffect, useMemo } from "react";
import { useAsync } from "react-async";
import { useCurrentTime } from "../contexts/CurrentTimeContext";
import { usePlace } from "../contexts/PlaceContext";
import { GAME_STATUS, LEADERBOARD_STATUS } from "../contexts/ProgrammeContext";
import useStompTopics from "../hooks/useStompTopics";
import FirebaseAnalyticsService from "../services/FirebaseAnalyticsService";
import GameService from "../services/GameService";

const syncGame = ([], { pub }) => GameService.getActiveGame(pub);
const getGame = ({ pub }) => GameService.getActiveGame(pub);
const getLeaderboard = ([gameId, pubId]) => GameService.getLeaderboard(gameId, pubId);

const ProgrammeController = () => {
  const pub = usePlace();
  const { gameTopic, gameTopicCountdownStart, gameQuestionTopic } = useStompTopics();
  const { start: startAds } = useAds();
  const { status: gameStatus, start: startGame, finish: finishGame, resume: resumeGame, resetGame } = useGame();
  const { start: startQuestion, finish: finishQuestion, reveal } = useQuestion();
  const { status: leaderboardStatus, start: startLeaderboard, finish: finishLeaderboard } = useLeaderboard();

  const {
    data: game,
    run: runGetGame,
    setData: setGame,
    isRejected,
  } = useAsync({
    promiseFn: getGame,
    deferFn: syncGame,
    pub,
  });

  const { data: leaderboard, run: runGetLeaderboard, setData: setLeaderboard } = useAsync({ deferFn: getLeaderboard });

  const handleGame = useCallback(
    ({ status }) => {
      switch (status) {
        case "COUNTING_DOWN":
          setGame(null);
          resetGame();
          runGetGame(pub.id);
          break;
        case "LEADERBOARD":
          finishGame();
          startLeaderboard(leaderboard);
          break;
        case "ENDED":
          finishLeaderboard();
          break;
      }
    },
    [runGetGame, setGame, pub.id, finishGame, startLeaderboard, leaderboard, finishLeaderboard, resetGame]
  );

  const handleQuestion = useCallback(
    ({ id, state }) => {
      switch (state) {
        case "QUESTION":
          startQuestion(id);
          break;
        case "ANSWER":
          reveal();
          runGetLeaderboard(game.id, pub.id); // pre-fetch leaderboard
          break;
        case "LEADERBOARD":
          startLeaderboard(leaderboard);
          break;
        case "END":
          finishQuestion();
          break;
      }
    },
    [finishQuestion, game?.id, pub.id, reveal, runGetLeaderboard, startLeaderboard, startQuestion, leaderboard]
  );
  const getCurrentTime = useCurrentTime();
  const handleMessage = useCallback(
    (msg, destination) => {
      if (destination === gameTopic || destination === gameTopicCountdownStart) {
        FirebaseAnalyticsService.logGameLatency(getCurrentTime(), msg.time, pub);
        handleGame(msg);
      } else if (destination === gameQuestionTopic) {
        FirebaseAnalyticsService.logQuestionLatency(getCurrentTime(), msg.time, pub);
        handleQuestion(msg);
      }
    },
    [gameTopic, gameTopicCountdownStart, gameQuestionTopic, getCurrentTime, pub, handleGame, handleQuestion]
  );

  useEffect(() => {
    if (gameStatus === GAME_STATUS.idle && game) {
      const { status } = game;
      if (status === "IN_PROGRESS" || status === "LEADERBOARD") {
        resumeGame(game);
      } else if (status === "COUNTING_DOWN") {
        startGame(game);
      }
    }
  }, [game, gameStatus, pub.id, resumeGame, runGetGame, startGame]);

  // clean up
  useEffect(() => {
    if (gameStatus === GAME_STATUS.finished) {
      setGame(null);
    }
    if (leaderboardStatus === LEADERBOARD_STATUS.finished) {
      setLeaderboard(null);
      startAds();
    }
  }, [gameStatus, leaderboardStatus, setGame, setLeaderboard, startAds]);

  useEffect(() => {
    if (isRejected && !game) {
      startAds();
    }
  });

  const topics = useMemo(() => {
    const base = [gameTopicCountdownStart, gameTopic];
    if (game?.id) {
      return [...base, gameQuestionTopic];
    }
    return base;
  }, [game?.id, gameQuestionTopic, gameTopic, gameTopicCountdownStart]);

  return <StompClient topics={topics} onMessage={handleMessage} />;
};

export default ProgrammeController;

import React from "react";

export default function CrownIcon() {
  return (
    <svg
      width="3vw"
      height="4vh"
      viewBox="0 0 40 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline", margin: "auto" }}
    >
      <g filter="url(#filter0_di)">
        <path
          d="M18.8979 33.0287L15.2298 25.0195C15.0054 24.5296 15.0703 23.9201 15.39 23.5149L19.058 18.8658C19.475 18.3374 20.1722 18.3374 20.5891 18.8658L24.2572 23.5149C24.5769 23.9201 24.6418 24.5296 24.4174 25.0195L20.7493 33.0287C20.3464 33.9084 19.3008 33.9084 18.8979 33.0287Z"
          fill="#FFF8DE"
        />
      </g>
      <path
        d="M5.63135 32.3817C5.63135 31.7079 6.1941 31.1616 6.8883 31.1616H32.761C33.4552 31.1616 34.0179 31.7079 34.0179 32.3817V37.2548C34.0179 37.9286 33.4552 38.4748 32.761 38.4748H6.8883C6.19411 38.4748 5.63135 37.9286 5.63135 37.2548V32.3817Z"
        fill="#FF7A00"
      />
      <path
        d="M12.0207 12.703L17.6639 1.34602C18.5557 -0.448673 21.0897 -0.448672 21.9815 1.34602L27.6247 12.703C28.2986 14.0591 30.0197 14.4721 31.2246 13.5669L35.7872 10.1389C37.4877 8.86128 39.8753 10.2576 39.6276 12.3848L37.1237 33.8847C36.9805 35.1146 35.9494 36.0415 34.7244 36.0415H4.92102C3.69605 36.0415 2.66495 35.1146 2.52172 33.8847L0.0178265 12.3848C-0.229903 10.2576 2.15774 8.86128 3.85818 10.1389L8.42082 13.5669C9.62566 14.4721 11.3468 14.0591 12.0207 12.703Z"
        fill="url(#paint0_radial)"
      />
      <path
        d="M17.9259 17.5267C18.8932 16.286 20.7534 16.286 21.7207 17.5267L25.4645 22.3288C26.1559 23.2155 26.1559 24.4658 25.4645 25.3525L21.7207 30.1546C20.7534 31.3953 18.8932 31.3953 17.9259 30.1546L14.1821 25.3525C13.4907 24.4658 13.4907 23.2155 14.1821 22.3288L17.9259 17.5267Z"
        fill="url(#paint1_radial)"
      />
      <defs>
        <filter
          id="filter0_di"
          x="5.73521"
          y="11.448"
          width="28.1768"
          height="33.9429"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.34049" />
          <feGaussianBlur stdDeviation="4.68098" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.68 0" />
          <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2.34049" />
          <feGaussianBlur stdDeviation="2.34049" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.914286 0 0 0 0 0.55 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.7441 14.2946) rotate(56.9028) scale(25.9589 23.1022)"
        >
          <stop stopColor="#FFEB81" />
          <stop offset="0.682292" stopColor="#FFC839" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.3525 20.9918) rotate(65.4804) scale(9.75901 10.3936)"
        >
          <stop stopColor="#FF7D16" />
          <stop offset="1" stopColor="#FF3E12" />
        </radialGradient>
      </defs>
    </svg>
  );
}

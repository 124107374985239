import PropTypes from "prop-types";
import { Line } from "rc-progress";
import React, { useMemo } from "react";
import { useElapsedTime } from "use-elapsed-time";

export default function LinearTimer({ duration, time, onComplete }) {
  const { elapsedTime } = useElapsedTime({
    isPlaying: true,
    duration,
    startAt: time,
    updateInterval: 0.2,
    onComplete,
  });

  const percent = useMemo(() => (1 - elapsedTime / duration) * 100, [duration, elapsedTime]);

  return <Line percent={percent} trailWidth={5} strokeWidth={5} strokeColor="#BA65F8" trailColor="#28264F" />;
}

LinearTimer.propTypes = {
  duration: PropTypes.number.isRequired,
  time: PropTypes.number,
  onComplete: PropTypes.func,
};

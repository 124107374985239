import { css } from "@emotion/css";
import styled from "@emotion/styled";

const GameSoon = () => (
  <div>
    <Label
      className={css`
        font-size: 2vw;
      `}
    >
      Game starts
    </Label>
    <Label
      className={css`
        font-size: 9vw;
      `}
    >
      Soon
    </Label>
  </div>
);

const Label = styled.div`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
`;

export default GameSoon;

import { css } from "@emotion/css";
import styled from "@emotion/styled";
import logo from "assets/Images/logo.webp";
import { GridColumn, GridRow } from "emotion-flex-grid";
import React, { useMemo } from "react";
import { AspectRatio } from "react-aspect-ratio";
import { GAME_STATUS, useGame } from "../../contexts/ProgrammeContext";

const withGameBoard = (WrappedComponent) => {
  const Component = (props) => {
    const { status, game } = useGame();
    const needToAdjust = useMemo(() => status === GAME_STATUS.started, [status]);

    return (
      <Container>
        <GridColumn
          width={needToAdjust ? 6 : 9}
          align="center"
          className={css`
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <WrappedComponent {...props} />
        </GridColumn>
        <GridColumn
          width={needToAdjust ? 6 : 3}
          offset={needToAdjust ? 0 : 1}
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <AspectRatio
            ratio={1.5}
            style={{
              width: needToAdjust ? "50%" : "90%",
              paddingLeft: needToAdjust ? "0" : "3vw",
              marginBottom: 15,
            }}
          >
            <img
              src={logo}
              alt="logo"
              className={css`
                object-fit: contain;
              `}
            />
          </AspectRatio>
          <AspectRatio ratio={1} style={{ width: needToAdjust ? "50%" : "80%", marginBottom: 15 }}>
            <div
              className={css`
                border-radius: 40px;
                overflow: hidden;
              `}
            >
              <img src={game?.qrcode?.qrCode} alt="qr-code" />
            </div>
          </AspectRatio>
          <Label>Scan to Play!</Label>
        </GridColumn>
      </Container>
    );
  };

  Component.displayName = `withGameBoard(${WrappedComponent.displayName || WrappedComponent.name})`;

  return Component;
};

const Container = styled(GridRow)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Label = styled.span`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  font-size: 3vw;
  margin-top: 26px;
`;

export default withGameBoard;

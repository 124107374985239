import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

const ImageItem = ({ url }) => <Image src={url} />;

const Image = styled.img`
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  background-color: black;
`;

ImageItem.propTypes = {
  url: PropTypes.string.isRequired,
};

export default React.memo(ImageItem);

import { css } from "@emotion/css";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Durations from "../../../utils/Durations";

const GameTimer = ({ seconds }) => (
  <div>
    <Label
      className={css`
        font-size: 2vw;
      `}
    >
      Game starts in
    </Label>
    <Label
      className={css`
        font-size: 9vw;
      `}
    >
      {Durations.format(seconds)}
    </Label>
  </div>
);

const Label = styled.div`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
`;

GameTimer.propTypes = {
  seconds: PropTypes.number.isRequired,
};

export default GameTimer;

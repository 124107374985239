import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/css";

const GameProgressIndicator = ({ current, total }) => (
  <div
    className={css`
      margin: 1vh 1vw;
      opacity: 0.7;
      margin-top: 16px;
    `}
  >
    <span
      className={css`
        font-weight: 700;
        font-size: 2vw;
      `}
    >
      Question {current + 1}
    </span>
    <span
      className={css`
        font-weight: 400;
        font-size: 1.5vw;
      `}
    >
      /{total}
    </span>
    <div
      className={css`
        margin-top: 8px;
        border-bottom: #8175c1 solid 1px;
        opacity: 0.7;
      `}
    />
  </div>
);

GameProgressIndicator.defaultProps = {
  total: 0,
};

GameProgressIndicator.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default GameProgressIndicator;

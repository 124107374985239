import CircleTimer from "components/Timer/CircleTimer";
import useBreakPoint from "hooks/useBreakPoint";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import withGameTimer from "../../handlers/withGameTimer";
import GameSoon from "./components/GameSoon";
import GameTimer from "./components/GameTimer";

const GameCountDown = ({ duration, time }) => {
  const { deviceWidth } = useBreakPoint();
  const [clock, setClock] = useState();

  useEffect(() => {
    if (deviceWidth < 2400) {
      setClock({ size: 650, width: 20 });
    } else if (deviceWidth > 2400 && deviceWidth < 2880) {
      setClock({ size: 900, width: 25 });
    } else if (deviceWidth > 2880 && deviceWidth < 5760) {
      setClock({ size: 1200, width: 35 });
    } else if (deviceWidth > 5760) {
      setClock({ size: 2200, width: 65 });
    }
  }, [deviceWidth]);

  const renderTimerInfo = useCallback(({ remainingTime }) => {
    if (remainingTime > 0) {
      return <GameTimer seconds={remainingTime} />;
    } else {
      return <GameSoon />;
    }
  }, []);

  return (
    <CircleTimer
      duration={duration}
      time={time}
      clockSize={clock?.size}
      strokeWidth={clock?.width}
      renderInfo={renderTimerInfo}
    />
  );
};

GameCountDown.propTypes = {
  duration: PropTypes.number,
  time: PropTypes.number,
};

export default withGameTimer(GameCountDown);

const Strings = {
  random: () => {
    const { customAlphabet } = require("nanoid");
    const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const nanoid = customAlphabet(alphabet, 10);
    return Strings.chuck(nanoid(), 2).join(":");
  },
  chuck: (str, n) => {
    const result = [];
    var i;
    var len;

    for (var i = 0, len = str.length; i < len; i += n) {
      result.push(str.substr(i, n));
    }

    return result;
  },
};

export default Strings;

import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useAsync } from "react-async";
import useLocalStorage from "react-use-localstorage";
import PlaceService from "services/PlaceService";
import Strings from "../utils/Strings";

const KEY_ACCESS_TOKEN = "KEY.ACCESS_TOKEN";

const promiseFn = ({ accessToken }) => PlaceService.getMyPlace(accessToken);

const PlaceContext = React.createContext();

export const usePlace = () => {
  const { place } = useContext(PlaceContext);
  return place;
};

const useAccessToken = () => {
  const [savedAccessToken, setSavedAccessToken] = useLocalStorage(KEY_ACCESS_TOKEN);
  const [accessToken, _setAccessToken] = useState(savedAccessToken);

  const setAccessToken = useCallback(
    (token) => {
      setSavedAccessToken(token);
      _setAccessToken(token);
    },
    [setSavedAccessToken]
  );

  return [accessToken, setAccessToken];
};

export const PlaceProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useAccessToken();

  const { data: place, status } = useAsync({
    promiseFn,
    accessToken,
    onReject: (error) => {
      if (error.response?.status === 404) {
        setAccessToken(Strings.random());
      }
    },
  });

  return <PlaceContext.Provider value={{ status, place, accessToken }}>{children}</PlaceContext.Provider>;
};

PlaceProvider.propTypes = {
  children: PropTypes.node,
};

export default PlaceContext;

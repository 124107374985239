import api from "../api/api";

const AdsService = {
  getAds: async (placeId) => {
    const res = await api.get(`/api/v1/promotions/pub/${placeId}`);
    return res.data.promotions?.map((it) => ({ ...it, type: it.promotion.type, url: it.promotion.url }));
  },
};

export default AdsService;

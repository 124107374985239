import { PlaceProvider } from "contexts/PlaceContext";
import React from "react";
import "react-aspect-ratio/aspect-ratio.css";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { CurrentTimeProvider } from "./contexts/CurrentTimeContext";

ReactDOM.render(
  <React.StrictMode>
    <PlaceProvider>
      <CurrentTimeProvider>
        <App />
      </CurrentTimeProvider>
    </PlaceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

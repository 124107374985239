import StompClient from "components/StompClient";
import { usePlace } from "contexts/PlaceContext";
import { GAME_STATUS, useGame } from "contexts/ProgrammeContext";
import React, { useCallback, useMemo, useState } from "react";
import FirebaseAnalyticsService from "services/FirebaseAnalyticsService";
import Durations from "../utils/Durations";
import { useCurrentTime } from "../contexts/CurrentTimeContext";
import useStompTopics from "hooks/useStompTopics";

const withGameTimer = (WrappedComponent) => {
  const Component = (props) => {
    const { gameTopicCountdown } = useStompTopics();
    const getCurrentTime = useCurrentTime();
    const [syncedTime, setTime] = useState();
    const { game, status } = useGame();
    const duration = useMemo(() => game.countdownDurations * 60, [game.countdownDurations]);
    const time = useMemo(
      () => Math.abs(Durations.diff(getCurrentTime(), game.startDate)),
      [game.startDate, getCurrentTime]
    );
    const place = usePlace();
    const handleOnMessage = useCallback(
      ({ remaining, time }) => {
        FirebaseAnalyticsService.logGameCountDownLatency(getCurrentTime(), time, place);
        setTime(remaining);
      },
      [place, getCurrentTime]
    );

    return (
      <>
        <WrappedComponent {...props} duration={duration} time={syncedTime || time} />
        {status === GAME_STATUS.started && <StompClient topics={[gameTopicCountdown]} onMessage={handleOnMessage} />}
      </>
    );
  };

  Component.displayName = `withGameTimer(${WrappedComponent.displayName || WrappedComponent.name})`;

  return Component;
};

export default withGameTimer;

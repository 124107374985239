import PropTypes from "prop-types";
import React, { useCallback, useContext, useReducer } from "react";
import Arrays from "../utils/Arrays";

const ProgrammeContext = React.createContext();

export const GAME_STATUS = {
  idle: "IDLE",
  started: "STARTED",
  playing: "PLAYING",
  finished: "FINISHED",
};

export const QUESTION_STATUS = { idle: "IDLE", displaying: "DISPLAYING", revealed: "REVEALED", finished: "FINISHED" };

export const LEADERBOARD_STATUS = {
  idle: "IDLE",
  displaying: "DISPLAYING",
  finished: "FINISHED",
};

export const ADS_STATUS = { idle: "IDLE", displaying: "DISPLAYING", finished: "FINISHED" };

const initialState = {
  game: undefined,
  gameStatus: GAME_STATUS.idle,
  questionStatus: QUESTION_STATUS.idle,
  leaderboardStatus: LEADERBOARD_STATUS.idle,
  adsStatus: ADS_STATUS.idle,
  questions: {},
  question: undefined,
  leaderboard: undefined,
};

const actionTypes = {
  game: {
    question: {
      start: "START_QUESTION",
      reveal: "REVEAL_ANSWER",
      finish: "FINISH_QUESTION",
    },
    nextGame: "NEXT_GAME",
    start: "START_GAME",
    resume: "RESUME_GAME",
    finish: "FINISH_GAME",
  },
  leaderboard: {
    start: "START_LEADERBOARD",
    finish: "FINISH_LEADERBOARD",
  },
  ads: {
    start: "START_ADS",
    finish: "FINISH_ADS",
  },
};

const reducer = (state, action = {}) => {
  const game = action.data?.game;
  const questions = game?.quiz?.reduce(
    (acc, value, index) => ({
      ...acc,
      [value.id]: { ...value, index, hasLongAnswer: value.answers.some((it) => it.answer.length > 20) },
    }),
    {}
  );

  switch (action.type) {
    case actionTypes.ads.start:
      return {
        ...initialState,
        adsStatus: ADS_STATUS.displaying,
      };
    case actionTypes.ads.finish:
      return { ...initialState, adsStatus: ADS_STATUS.finished };
    case actionTypes.leaderboard.start:
      return {
        ...state,
        questionStatus: QUESTION_STATUS.idle,
        leaderboardStatus: LEADERBOARD_STATUS.displaying,
        leaderboard: action.data.leaderboard,
      };
    case actionTypes.leaderboard.finish:
      return { ...state, leaderboardStatus: LEADERBOARD_STATUS.finished };
    case actionTypes.game.start:
      return {
        ...initialState,
        game,
        gameStatus: GAME_STATUS.started,
        questions,
      };
    case actionTypes.game.resume:
      const question = Arrays.findLast(Object.values(questions), (it) => it.state !== "END" && it.state !== "PENDING");
      return {
        ...initialState,
        game,
        gameStatus: game.status === "LEADERBOARD" ? GAME_STATUS.finished : GAME_STATUS.playing,
        questionStatus:
          question?.state === "LEADERBOARD"
            ? QUESTION_STATUS.finished
            : question?.state === "ANSWER"
            ? QUESTION_STATUS.revealed
            : question
            ? QUESTION_STATUS.displaying
            : QUESTION_STATUS.idle,
        questions,
        question: { ...question, reveal: question?.state === "ANSWER" },
        leaderboardStatus: game.status === "LEADERBOARD" ? LEADERBOARD_STATUS.displaying : LEADERBOARD_STATUS.idle,
        leaderboard: game.leaderboard,
      };
    case actionTypes.game.finish:
      return { ...initialState, gameStatus: GAME_STATUS.finished };
    case actionTypes.game.reset:
      return initialState;
    case actionTypes.game.question.start:
      return {
        ...state,
        gameStatus: GAME_STATUS.playing,
        leaderboardStatus: LEADERBOARD_STATUS.idle,
        questionStatus: QUESTION_STATUS.displaying,
        question: state.questions[action.data.id],
      };
    case actionTypes.game.question.reveal:
      return { ...state, questionStatus: QUESTION_STATUS.revealed, question: { ...state.question, reveal: true } };
    case actionTypes.game.question.finish:
      return {
        ...state,
        questionStatus: QUESTION_STATUS.finished,
      };
  }
};

export const useProgramme = () => {
  const { state, ...rest } = useContext(ProgrammeContext);
  return { ...state, ...rest };
};

export const useGame = () => {
  const { game, gameStatus, starters, finishers, resumers, resetGame } = useProgramme();
  return { game, status: gameStatus, start: starters.game, resume: resumers.game, finish: finishers.game, resetGame };
};

export const useQuestion = () => {
  const { question, questions, questionStatus, starters, finishers, revealAnswer } = useProgramme();
  const total = Object.keys(questions)?.length ?? 0;
  return {
    question,
    current: question?.index ?? 0,
    total,
    status: questionStatus,
    start: starters.question,
    finish: finishers.question,
    reveal: revealAnswer,
  };
};

export const useLeaderboard = () => {
  const { leaderboard, leaderboardStatus, starters, finishers } = useProgramme();
  return {
    leaderboard,
    status: leaderboardStatus,
    start: starters.leaderboard,
    finish: finishers.leaderboard,
  };
};

export const useAds = () => {
  const { adsStatus, starters, finishers } = useProgramme();
  return { status: adsStatus, start: starters.ads, finish: finishers.ads };
};

export const ProgrammeProvider = ({ children }) => {
  const [state, _dispatch] = useReducer(reducer, initialState);

  const dispatch = useCallback((...args) => _dispatch(...args), []);

  const startGame = useCallback(
    (game) => {
      dispatch({ type: actionTypes.game.start, data: { game } });
    },
    [dispatch]
  );

  const resumeGame = useCallback(
    (game) => {
      if (state.gameStatus === GAME_STATUS.finished) {
        return;
      }
      dispatch({ type: actionTypes.game.resume, data: { game } });
    },
    [dispatch, state.gameStatus]
  );

  const finishGame = useCallback(() => {
    dispatch({ type: actionTypes.game.finish });
  }, [dispatch]);

  const resetGame = useCallback(() => {
    dispatch({ type: actionTypes.game.reset });
  }, [dispatch]);

  const startQuestion = useCallback(
    (id) => {
      dispatch({ type: actionTypes.game.question.start, data: { id } });
    },
    [dispatch]
  );

  const revealAnswer = useCallback(() => {
    if (state.questionStatus !== QUESTION_STATUS.displaying) {
      return;
    }
    dispatch({ type: actionTypes.game.question.reveal });
  }, [dispatch, state.questionStatus]);

  const finishQuestion = useCallback(() => {
    dispatch({ type: actionTypes.game.question.finish });
  }, [dispatch]);

  const startLeaderboard = useCallback(
    (leaderboard) => {
      if (state.leaderboardStatus === LEADERBOARD_STATUS.displaying) {
        return;
      }
      dispatch({ type: actionTypes.leaderboard.start, data: { leaderboard } });
    },
    [dispatch, state.leaderboardStatus]
  );

  const finishLeaderboard = useCallback(() => {
    dispatch({ type: actionTypes.leaderboard.finish });
  }, [dispatch]);

  const startAds = useCallback(() => {
    if (state.adsStatus === ADS_STATUS.displaying) {
      return;
    }
    dispatch({ type: actionTypes.ads.start });
  }, [dispatch, state.adsStatus]);

  const finishAds = useCallback(() => {
    dispatch({ type: actionTypes.ads.finish });
  }, [dispatch]);

  return (
    <ProgrammeContext.Provider
      value={{
        state,
        starters: { ads: startAds, leaderboard: startLeaderboard, game: startGame, question: startQuestion },
        finishers: { ads: finishAds, leaderboard: finishLeaderboard, game: finishGame, question: finishQuestion },
        resumers: { game: resumeGame },
        revealAnswer,
        resetGame,
      }}
    >
      {children}
    </ProgrammeContext.Provider>
  );
};

ProgrammeProvider.propTypes = {
  children: PropTypes.node,
};

import styled from "@emotion/styled";
import backgroundImage from "assets/background/background.png";
import backgroundImageLarge from "assets/background/main-background.png";
import PropTypes from "prop-types";
import React from "react";

const Section = ({ children }) => (
  <FullScreen>
    <Container>{children}</Container>
  </FullScreen>
);

Section.propTypes = {
  children: PropTypes.node,
};

export const FullScreen = styled.section`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-image: url(${backgroundImageLarge});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 960px) {
    background-image: url(${backgroundImage});
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  padding: 100px 85px;
`;

export default Section;

import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Section from "components/Shared/Section";
import AspectRatio from "react-aspect-ratio";
import logo from "assets/Images/logo.webp";

const Contact = ({ macAddress }) => {
  return (
    <Section>
      <Container>
        <AspectRatio ratio={2.27} style={{ width: "50%" }}>
          <img src={logo} alt="logo" />
        </AspectRatio>
        <Label>Please Contact Us via email or phone to Register Your Service</Label>
        <Label>{process.env.REACT_APP_CONTACT_EMAIL}</Label>
        <Label>{process.env.REACT_APP_CONTACT_PHONE_NUMBER}</Label>
        <Label>{macAddress}</Label>
      </Container>
    </Section>
  );
};

Contact.propTypes = {
  macAddress: PropTypes.any.isRequired,
};

const Label = styled.span`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  font-size: 2vw;
  margin-top: 26px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  padding: 100px 85px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default Contact;

import React from "react";
import { GAME_STATUS, QUESTION_STATUS, useProgramme } from "../../contexts/ProgrammeContext";
import GameLeaderboard from "../Leaderboard/GameLeaderboard";
import GameCountDown from "./GameCountDown";
import GameQuestion from "./GameQuestion";
import withGameBoard from "./withGameBoard";

const Game = () => {
  const { gameStatus, questionStatus } = useProgramme();

  if (gameStatus === GAME_STATUS.started) {
    return <GameCountDown />;
  } else if (questionStatus === QUESTION_STATUS.displaying) {
    return <GameQuestion />;
  } else if (questionStatus === QUESTION_STATUS.revealed) {
    return <GameQuestion revealed />;
  } else {
    return <GameLeaderboard />;
  }
};

export default withGameBoard(Game);

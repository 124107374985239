import PropTypes from "prop-types";
import React from "react";

const selected = {
  background: "linear-gradient(89.66deg, #6CECFD 0.17%, #2E9A93 47.68%, #108C6F 88.96%)",
  border: "none",
};

const unselected = {
  backgroundColor: "transparent",
  border: "0.2vw solid #8175c1",
};

const base = {
  width: "100%",
  minHeight: "9vh",
  borderRadius: "5vw",
  boxSizing: "border-box",
  fontSize: "3vw",
  fontWeight: 700,
  color: "#ffffff",
  margin: "1vh 0",
};

const GameAnswer = ({ text, isRight }) => (
  <button style={{ ...base, ...(isRight ? selected : unselected) }}>{text}</button>
);

GameAnswer.propTypes = {
  isRight: false,
};

GameAnswer.propTypes = {
  text: PropTypes.string.isRequired,
  isRight: PropTypes.bool,
};

export default GameAnswer;

import styled from "@emotion/styled";
import { useGame, useQuestion } from "contexts/ProgrammeContext";
import { GridColumn, GridRow } from "emotion-flex-grid";
import PropTypes from "prop-types";
import { Line } from "rc-progress";
import React, { useMemo } from "react";
import LinearTimer from "../../components/Timer/LinearTimer";
import Durations from "../../utils/Durations";
import GameAnswer from "./components/GameAnswer";
import GameProgressIndicator from "./components/GameProgressIndicator";
import GameQuestionHeader from "./components/GameQuestionHeader";
import { useCurrentTime } from "../../contexts/CurrentTimeContext";

const GameQuestion = ({ revealed }) => {
  const { game } = useGame();
  const { question: data, current, total } = useQuestion();
  const { startDate, answers, question, hasLongAnswer } = data;
  const getCurrentTime = useCurrentTime();
  const time = useMemo(() => Math.abs(Durations.diff(getCurrentTime(), startDate)), [startDate, getCurrentTime]);

  return (
    <Container>
      <GameQuestionHeader>
        {revealed ? (
          <Line percent={100} trailWidth={5} strokeWidth={5} strokeColor="transparent" trailColor="transparent" />
        ) : (
          <LinearTimer duration={game.questionDurations} time={time} />
        )}
        <GameProgressIndicator current={current} total={total} />
      </GameQuestionHeader>
      <QuestionContainer>
        <Question>{question}</Question>
      </QuestionContainer>
      <GridRow wrap="wrap" align="center" justify="between">
        {answers.map(({ answer, isRight }, index) => (
          <GridColumn key={index} width={hasLongAnswer ? "100%" : "48%"} mb={["m"]}>
            <GameAnswer text={answer} isRight={isRight && revealed} />
          </GridColumn>
        ))}
      </GridRow>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const Question = styled.div`
  font-weight: 700;
  font-size: 3.5vw;
  line-height: 133.4%;
  text-align: center;
  color: #ffffff;
`;

GameQuestion.defaultProps = {
  revealed: false,
};

GameQuestion.propTypes = {
  revealed: PropTypes.bool,
};

export default GameQuestion;

import { css } from "@emotion/css";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player/lazy";

const presentationMode = {
  loop: true,
  muted: true,
  playsinline: true,
  progressInterval: 0,
  width: "100%",
  height: "100vh",
};

const VideoItem = ({ url, isSelected, isPrevious }) => {
  const ref = useRef();
  const timeoutRef = useRef();

  useEffect(() => {
    if (isPrevious && !isSelected) {
      timeoutRef.current = setTimeout(() => {
        try {
          ref.current?.seekTo(0);
        } catch (e) {
          // no op
        }
      }, 1000);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [isPrevious, isSelected]);

  return (
    <ReactPlayer
      ref={ref}
      {...presentationMode}
      playing={isSelected}
      url={url}
      style={{ backgroundColor: "black" }}
      className={css`
        video {
          object-fit: cover;
        }
      `}
    />
  );
};

VideoItem.defaultProps = {
  isSelected: false,
  isPrevious: false,
};

VideoItem.propTypes = {
  url: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isPrevious: PropTypes.bool,
};

export default React.memo(VideoItem);

import PropTypes from "prop-types";
import React, { useCallback, useReducer } from "react";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import useTimeout from "../../hooks/useTimeout";
import ImageItem from "./ImageItem";
import UpcomingGameCountdown from "./UpcomingGameCountdown";
import VideoItem from "./VideoItem";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useCompareTime } from "contexts/CurrentTimeContext";

const presentationMode = {
  swipeable: false,
  showArrows: false,
  stopOnHover: false,
  showThumbs: false,
  showStatus: false,
  showIndicators: false,
  animationHandler: "fade",
  transitionTime: 1000,
};

function getNextIndex(count, currentIndex) {
  const _nextIndex = currentIndex + 1;
  return _nextIndex >= count ? 0 : _nextIndex;
}

const initialState = {
  ads: [],
  adsIndex: 0,
  imaginaryIndex: 0,
  gameStartsAt: null,
  playTime: 0,
  content: "ads",
};

const actionTypes = {
  showAds: "showAds",
  showUpcomingGame: "showUpcomingGame",
  reset: "reset",
};

const reducer = (state, action = {}) => {
  const adsLength = state.ads.length;
  switch (action.type) {
    case actionTypes.showAds:
      const nextAds = getNextIndex(adsLength, state.adsIndex);
      return {
        ...state,
        adsIndex: nextAds,
        imaginaryIndex: nextAds,
        playTime: state.ads[nextAds].duration,
        content: "ads",
      };
    case actionTypes.showUpcomingGame:
      return {
        ...state,
        imaginaryIndex: adsLength,
        playTime: 5,
        content: "game",
      };
    case actionTypes.reset:
      return {
        ...initialState,
        ads: action.data.ads,
        gameStartsAt: action.data.gameStartsAt,
        playTime: action.data.ads[0].duration,
      };
  }
};

const Promotionals = ({ ads, gameStartsAt }) => {
  const [state, _dispatch] = useReducer(reducer, {
    ...initialState,
    ads,
    gameStartsAt,
    playTime: ads[0].duration,
  });

  const compare = useCompareTime();

  const dispatch = useCallback((...args) => _dispatch(...args), []);

  useTimeout(
    () => {
      if (state.content === "ads" && state.gameStartsAt) {
        dispatch({ type: actionTypes.showUpcomingGame });
      } else if (state.content === "game" || !state.gameStartsAt) {
        dispatch({ type: actionTypes.showAds });
      }
    },
    state.playTime * 1000,
    [state.imaginaryIndex]
  );

  useDeepCompareEffect(() => {
    const delta = compare(gameStartsAt);
    const localGameStartAt = delta > 0 ? gameStartsAt : null;
    dispatch({ type: actionTypes.reset, data: { ads, gameStartsAt: localGameStartAt } });
  }, [dispatch, gameStartsAt, ads]);

  const renderItem = useCallback((item, props) => {
    if (!item) return null;
    return <item.type {...item.props} {...props} />;
  }, []);

  return (
    <Carousel {...presentationMode} selectedItem={state.imaginaryIndex} renderItem={renderItem}>
      {state.ads.map(({ type, url }, index) => {
        if (type === "IMAGE") {
          return <ImageItem key={index} url={url} />;
        } else if (type === "VIDEO") {
          return <VideoItem key={index} url={url} />;
        }
      })}
      {state.gameStartsAt && <UpcomingGameCountdown />}
    </Carousel>
  );
};

Promotionals.defaultProps = {
  ads: [],
};

Promotionals.propTypes = {
  ads: PropTypes.array,
  gameStartsAt: PropTypes.string,
};

export default Promotionals;

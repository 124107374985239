import api from "../api/api";

const PlaceService = {
  getMyPlace: async (accessToken) => {
    const res = await api.get(`api/v1/projectors/pub`, { headers: { "mac-address": accessToken } });
    return res.data;
  },
};

export default PlaceService;

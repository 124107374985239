import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const Durations = {
  format: (duration) => {
    let minutes = Math.floor(duration / 60);
    let seconds = duration - minutes * 60;

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  },
  formatToUpcomingGame: (seconds) => dayjs.duration(seconds < 0 ? 0 : seconds, "seconds").format("DD:HH:mm:ss"),
  diff: (currentDate, date) => dayjs(currentDate).diff(dayjs(date), "second"),
};

export default Durations;

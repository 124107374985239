import React from "react";
import Section from "../components/Shared/Section";
import {
  ADS_STATUS,
  GAME_STATUS,
  LEADERBOARD_STATUS,
  useAds,
  useGame,
  useLeaderboard,
} from "../contexts/ProgrammeContext";
import { useScreensaver } from "../contexts/ScreensaverContext";
import Promotionals from "../screen/Promotion/Promotionals";
import Game from "./Game/Game";
import FinalLeaderboard from "./Leaderboard/FinalLeaderboard";

const Programme = () => {
  const { ads, gameStartsAt } = useScreensaver();
  const { game, status: gameStatus } = useGame();
  const { status: leaderboardStatus, leaderboard } = useLeaderboard();
  const { status: adsStatus } = useAds();

  if (adsStatus === ADS_STATUS.displaying && ads) {
    return <Promotionals ads={ads} gameStartsAt={gameStartsAt} />;
  }

  if (game && gameStatus !== GAME_STATUS.finished) {
    return (
      <Section>
        <Game />
      </Section>
    );
  }

  if (leaderboard && leaderboardStatus === LEADERBOARD_STATUS.displaying) {
    return (
      <Section>
        <FinalLeaderboard data={leaderboard?.participants} />
      </Section>
    );
  }

  return <Section />;
};

export default Programme;
